import { useAuthStore } from "~/stores/auth";

export default defineNuxtRouteMiddleware(async (to, from) => {
  const authStore = useAuthStore();

  // If the user is authenticated but we don't have the user object yet
  if (authStore.isAuthenticated && authStore.user === undefined) {
    await authStore.fetchUser();
  }

  if (!authStore.isAuthenticated) {
    await authStore.checkUserSession();
    if (!authStore.isAuthenticated) {
      return navigateTo("/auth/login");
    }
  }

  if (
    !authStore.user?.verified &&
    to.path !== "/auth/verify-email" &&
    to.path !== "/auth/verified"
  ) {
    return navigateTo("/auth/verify-email");
  }
});
